
.contact {
    background-color: orange;
    width: 100%;
    height: 80vh;
    background-image: url('https://sportschaplaincy.org.uk/wp-content/uploads/2020/07/gym3.jpg');
    background-size: 100% 80vh;
    background-repeat: no-repeat ;
   
}

.contact::before {
    background-color: rgba(0, 0, 0, 0.604);
    content: "";
    height: 80vh;
    width: 100%;
    position: absolute;
    z-index: 1;
}

.main {
    padding-top: 1.3rem;
}

.main h3 {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

form {
    text-align: center;
    position: relative;
    z-index: 1;
    margin-top: 5rem;
    border: 1px solid orange;
    padding: 1rem;
    width: 40vw;
    margin-left: 27vw;
}

form label {
    color: gold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
}

form input {
    margin: 1rem;
    padding: 0.3rem;
    border: 2px solid red;
    border-radius: 8px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 1rem;
    text-align: center;
    background-color: black;
    color: white;
    opacity: 80%;
}

 input:hover, input:active {
    border: 3px solid blue;
    background-color: black;
    color: white;
    padding: 0.8rem;
} 
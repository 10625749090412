.sign {
        background-color: burlywood;
        width: 100%;
        height: 80vh;
        position: relative;
        z-index: 1;
        background-size: 100% 70vh;
}

.sign::before {
        content: "";
        width: 100%;
        height: 80vh;
        position: absolute;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.4);
}

.ph {
        color: white;
        position: relative;
        z-index: 1;
        text-align: center;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        padding-top: 1.2rem;
        border-bottom: 1px solid gold;
        width: 20vw;
        padding: 1rem;
        margin-left: 40vw;
}

.card {
          margin-left: 2rem;   
}


.mas {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        margin-top: 2.5rem;
}

.dis {
        background-color: gold;
        height: 10vh;
        border: 1px solid black;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        text-align: center;
        box-sizing: border-box;
        padding-top: 1.3rem;
        font-size: 30px;
        font-family:fantasy;
}

.card:hover {
        border: 6px solid black;
        border-radius: 16px;
}

.dis:hover {
        background-color: black;
        color: gold;
}


